import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    IconButton,
    Typography,
    Grid,
    Chip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
    fetchClientList,
    getHubList,
} from '../../store/actions/staffActionCreator';

import Modal from './Modal';
import { CustomTextField, StyledMenuItem } from '../Textfield';
import { CustomDatePicker, CustomTimePicker } from '../DateTimePicker';
import moment from 'moment';
import {
    createShiftTiming,
    editShiftTiming,
} from '../../store/actions/settingsActionCreator';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CustomSwitch from '../Switch';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { weekDays } from '../../utils/helper';
import { is } from 'date-fns/locale';
const validationSchema = yup.object({
    hubId: yup.string('Invalid hubId').required('Hub cannot be empty'),
    name: yup
        .string('Invalid Shift Name')
        .trim()
        .required('Shift Name cannot be empty'),
    shortName: yup
        .string('Invalid Short Name')
        .max(10, 'Only 10 letters are allowed')
        .trim()
        .required('Short Name cannot be empty'),
    endTimeEpochMillis: yup
        .string('Invalid end date')
        .trim()
        .required('End date cannot be empty'),
    startTimeEpochMillis: yup
        .string('Invalid start date')
        .trim()
        .required('Start date cannot be empty'),
    isSlotShift: yup.boolean().required(),
});

function AddShiftTimingModal({
    open,
    onClose,
    isEdit = false,
    data = {},
    isEnabledXUtil,
    staff,
    orgId,
    dispatchEditStaffClient,
    dispatchFetchClientList,
    dispatchCreateShiftTiming,
    dispatchEditShiftTiming,
    dispatchGetHubList,
    isPinCodeOrg,
    isAmazonOrg,
    ...props
}) {
    // const classes = useStyles();
    const [isOpen, toggleModal] = useState(open);
    const [isloading, toggleloading] = useState(true);
    const handleClose = () => {
        // Setting form data to null before closing
        setState({
            customerId: '',
            staffCustCode: '',
        });
        setStateStatus({
            name: {},
            contactNumber: {},
            vehicleNumber: {},
        });
        toggleModal(!isOpen);
        !!onClose && onClose();
    };
    const [state, setState] = useState({
        hubId: data?.hubId ?? '',
        name: '',
        shortName: '',
        endTimeEpochMillis: moment().valueOf(),
        startTimeEpochMillis: moment().valueOf(),
        startDateEpochMillis: moment().startOf('day').valueOf(),
        endDateEpochMillis: moment().startOf('day').valueOf(),
        minEarnings: null,
        maxEarnings: null,
        slotSize: null,
        isSlotShift: data?.slotShift ?? false,
        rosterShift: data?.rosterShift ?? false,
        rosterDays: data?.rosterDays
            ? [
                  ...new Set(
                      data?.rosterDays?.split(',').filter(Boolean).map(Number),
                  ),
              ]
            : [],
        ...data,
    });
    console.log('state', state);
    const [stateStatus, setStateStatus] = useState({
        name: {},
        contactNumber: {},
        vehicleNumber: {},
        isSlotShift: false,
    });

    useEffect(() => {
        toggleloading(false);
        dispatchGetHubList(
            orgId,
            (resp) => {
                console.log('hubList success', resp, data);
            },
            (err) => {
                console.log('hubList error', err);
            },
        );
        if (isEdit) {
            console.log(
                'dattest',
                data,
                !!isPinCodeOrg || !!isAmazonOrg,
                data?.rosterDays?.split(',')?.filter((i) => !!i),
            );
            setState({
                customerId: data?.customerId ?? '',
                staffCustCode: data?.staffCustCode ?? '',
                id: data?.id,
                ...(!!isPinCodeOrg || !!isAmazonOrg
                    ? {
                          rosterShift: data?.rosterShift ?? false,
                          rosterDays: data?.rosterDays
                              ? [
                                    ...new Set(
                                        data?.rosterDays
                                            ?.split(',')
                                            .filter(Boolean)
                                            .map(Number),
                                    ),
                                ]
                              : [], // converting array to string
                      }
                    : {}),
            });
        }
    }, []);

    const formik = useFormik({
        initialValues: {
            ...state,
            rosterDays: data?.rosterDays
                ? [
                      ...new Set(
                          data?.rosterDays
                              ?.split(',')
                              .filter(Boolean)
                              .map(Number),
                      ),
                  ]
                : [],
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            toggleloading(true);
            console.log('ONSUBMIT', { ...values });
            const actionCall = isEdit
                ? dispatchEditShiftTiming
                : dispatchCreateShiftTiming;
            actionCall(
                {
                    ...values,
                    rosterDays: values?.rosterDays?.join(','), // converting array to string
                },
                (resp) => {
                    toggleloading(false);
                    handleClose();
                },
                (err) => {
                    toggleloading(false);
                },
            );
        },
    });

    const enableButton =
        !!formik.values?.name?.length &&
        !!formik.values?.shortName?.length &&
        !!formik.values.hubId &&
        !!formik.values.startTimeEpochMillis &&
        !!formik.values.endTimeEpochMillis &&
        !!formik.values.endTimeEpochMillis >=
            !!formik.values.startTimeEpochMillis &&
        (!formik.values?.rosterShift ||
            ((!!isPinCodeOrg || !!isAmazonOrg) &&
                formik.values?.rosterShift &&
                formik.values?.rosterDays?.length > 0)) &&
        (!formik.values?.isSlotShift ||
            (formik.values?.isSlotShift &&
                formik.values.endDateEpochMillis >=
                    formik.values.startDateEpochMillis &&
                formik.values.startDateEpochMillis >=
                    moment().startOf('day').valueOf() &&
                formik.values?.minEarnings > 0 &&
                formik.values?.maxEarnings > 0 &&
                formik.values?.maxEarnings >= formik.values?.minEarnings &&
                formik.values?.slotSize > 0));

    const actions = [
        <Button
            key={1}
            variant="contained"
            color="default"
            className={`cancelButton`}
            onClick={handleClose}
            disableElevation
        >
            {'Cancel'}
        </Button>,
        <Button
            key={2}
            variant="contained"
            color="primary"
            className={`saveButton`}
            type="submit"
            disableElevation
            disabled={!enableButton}
        >
            {isEdit ? 'Save' : 'Add'}
        </Button>,
    ];

    const handleToggle = (item) => {
        console.log('item', item, formik?.values?.rosterDays);
        const newSelected = !!formik?.values?.rosterDays?.includes(item?.index)
            ? formik?.values?.rosterDays?.filter((i) => i !== item?.index)
            : [...formik?.values?.rosterDays, item?.index];
        console.log('item', formik?.values?.rosterDays);

        formik.handleChange({
            target: {
                name: 'rosterDays',
                value: newSelected,
            },
        });
    };

    return (
        <Modal
            onClose={handleClose}
            open={isOpen}
            title={isEdit ? 'Edit Shift' : 'Add Shift'}
            actions={actions}
            loading={isloading}
            stickyActions={true}
            isForm={true}
            onSubmit={formik.handleSubmit}
        >
            <CustomTextField
                fullWidth={true}
                label="Shift Name"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={
                    formik.touched.name ? Boolean(formik.errors.name) : false
                }
                helperText={formik.touched.name && formik.errors.name}
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />
            <CustomTextField
                fullWidth={true}
                label="Short Name"
                id="shortName"
                name="shortName"
                value={formik.values.shortName}
                onChange={formik.handleChange}
                error={
                    formik.touched.shortName
                        ? Boolean(formik.errors.shortName)
                        : false
                }
                helperText={formik.touched.shortName && formik.errors.shortName}
                style={{ marginBottom: '16px' }}
                isRequired={true}
            />
            <CustomTextField
                label="Hub Name"
                select
                fullWidth={true}
                id="hubId"
                name="hubId"
                value={formik.values.hubId}
                onChange={formik.handleChange}
                style={{
                    marginBottom: '16px',
                    width: '100%',
                }}
                disabled={!!isEnabledXUtil && !!data?.hubId}
                error={
                    formik.touched.hubId ? Boolean(formik.errors.hubId) : false
                }
                helperText={formik.touched.hubId && formik.errors.hubId}
            >
                {staff?.hubs?.length &&
                    staff?.hubs.map((hub, index) => (
                        <StyledMenuItem key={index} value={hub.id}>
                            {hub.name}
                        </StyledMenuItem>
                    ))}
            </CustomTextField>

            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <CustomTimePicker
                    label={'Start Time'}
                    id="startTimeEpochMillis"
                    name="startTimeEpochMillis"
                    value={formik.values.startTimeEpochMillis}
                    onChange={(value) => {
                        formik.handleChange({
                            target: {
                                name: 'startTimeEpochMillis',
                                value: moment(value).valueOf(),
                            },
                        });
                    }}
                    style={{ width: '49%' }}
                    isRequired={true}
                    error={
                        formik.touched.startTimeEpochMillis
                            ? Boolean(formik.errors.startTimeEpochMillis)
                            : false
                    }
                    helperText={
                        formik.touched.startTimeEpochMillis &&
                        formik.errors.startTimeEpochMillis
                    }
                />
                <CustomTimePicker
                    label={'End Time'}
                    id="endTimeEpochMillis"
                    name="endTimeEpochMillis"
                    value={formik.values.endTimeEpochMillis}
                    onChange={(value) => {
                        formik.handleChange({
                            target: {
                                name: 'endTimeEpochMillis',
                                value:
                                    moment(value).format('hh:mma') === '12:00am'
                                        ? moment(
                                              moment(value).format(
                                                  'DD/MM/YYYY 11:59:59 PM',
                                              ),
                                          ).valueOf()
                                        : moment(value).valueOf(),
                            },
                        });
                    }}
                    style={{ width: '49%' }}
                    isRequired={true}
                    error={
                        formik.touched.endTimeEpochMillis
                            ? Boolean(formik.errors.endTimeEpochMillis)
                            : false
                    }
                    helperText={
                        formik.touched.endTimeEpochMillis &&
                        formik.errors.endTimeEpochMillis
                    }
                />
            </Box>
            {(isAmazonOrg || isPinCodeOrg) && (
                <Box mb={'8px'}>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: '18px',
                            }}
                        >
                            Slot Shift
                        </Typography>

                        <CustomSwitch
                            id="rosterShift"
                            name="rosterShift"
                            disabled={data?.rosterShift}
                            checked={formik.values.rosterShift}
                            onChange={(e) => {
                                formik.handleChange({
                                    target: {
                                        name: 'rosterShift',
                                        value: e.target.checked,
                                    },
                                });
                                formik.handleChange({
                                    target: {
                                        name: 'isSlotShift',
                                        value: false,
                                    },
                                });
                            }}
                        />
                    </Box>
                    {!!formik.values.rosterShift && (
                        <Box mt={'12px'}>
                            <Grid container spacing={1}>
                                {weekDays?.map((item) => (
                                    <Grid item>
                                        <Chip
                                            key={item?.index}
                                            label={item?.name}
                                            onClick={() => handleToggle(item)}
                                            color={
                                                formik?.values?.rosterDays?.includes(
                                                    item?.index,
                                                )
                                                    ? 'primary'
                                                    : 'default'
                                            }
                                            variant={
                                                formik?.values?.rosterDays?.includes(
                                                    item?.index,
                                                )
                                                    ? 'default'
                                                    : 'outlined'
                                            }
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    )}
                </Box>
            )}
            {!isAmazonOrg && !isPinCodeOrg && (
                <>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            style={
                                formik.values.isSlotShift
                                    ? {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          width: '50%',
                                      }
                                    : {
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          width: '100%',
                                      }
                            }
                        >
                            <Typography
                                style={{
                                    fontSize: '18px',
                                }}
                            >
                                Slot Shift
                            </Typography>

                            <CustomSwitch
                                id="isSlotShift"
                                name="isSlotShift"
                                disabled={data?.slotShift || data?.rosterShift}
                                checked={formik.values.isSlotShift}
                                onChange={(e) => {
                                    formik.handleChange({
                                        target: {
                                            name: 'isSlotShift',
                                            value: e.target.checked,
                                        },
                                    });
                                    formik.handleChange({
                                        target: {
                                            name: 'rosterShift',
                                            value: false,
                                        },
                                    });
                                }}
                            />
                        </Box>
                        {formik.values.isSlotShift && (
                            <CustomTextField
                                label="Slot Size"
                                variant="outlined"
                                inputProps={{
                                    type: 'number',
                                }}
                                id="slotSize"
                                name="slotSize"
                                value={formik.values.slotSize}
                                onChange={formik.handleChange}
                                style={{ marginBottom: '16px', width: '49%' }}
                                isRequired={true}
                            />
                        )}
                    </Box>

                    {formik.values.isSlotShift && (
                        <Box>
                            <Box
                                style={{
                                    marginTop: '16px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <CustomDatePicker
                                    id="startDateEpochMillis"
                                    name="startDateEpochMillis"
                                    label="Start Date"
                                    value={formik.values.startDateEpochMillis}
                                    onChange={(value) => {
                                        formik.handleChange({
                                            target: {
                                                name: 'startDateEpochMillis',
                                                value: moment(value)
                                                    .startOf('day')
                                                    .valueOf(),
                                            },
                                        });
                                    }}
                                    showInput={true}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                style={{
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                <InsertInvitationIcon size="small" />
                                            </IconButton>
                                        ),
                                    }}
                                    style={{
                                        width: '49%',
                                    }}
                                    isRequired={true}
                                    allowFutureDates={true}
                                    minDate={moment()}
                                    maxDate={moment(
                                        formik.values?.endDateEpochMillis,
                                    )
                                        .add(1, 'months')
                                        .valueOf()}
                                />
                                <CustomDatePicker
                                    id="endDateEpochMillis"
                                    name="endDateEpochMillis"
                                    label="End Date"
                                    allowFutureDates={true}
                                    value={formik.values.endDateEpochMillis}
                                    onChange={(value) => {
                                        formik.handleChange({
                                            target: {
                                                name: 'endDateEpochMillis',
                                                value: moment(value)
                                                    .startOf('day')
                                                    .valueOf(),
                                            },
                                        });
                                    }}
                                    showInput={true}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                style={{
                                                    padding: 0,
                                                    margin: 0,
                                                }}
                                            >
                                                <InsertInvitationIcon size="small" />
                                            </IconButton>
                                        ),
                                    }}
                                    style={{
                                        width: '49%',
                                    }}
                                    isRequired={true}
                                    minDate={moment(
                                        formik.values?.startDateEpochMillis,
                                    )}
                                    maxDate={moment(
                                        formik.values?.startDateEpochMillis,
                                    )
                                        .add(1, 'months')
                                        .valueOf()}
                                />
                            </Box>

                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <CustomTextField
                                    label="Min Earnings"
                                    variant="outlined"
                                    inputProps={{
                                        type: 'number',
                                    }}
                                    id="minEarnings"
                                    name="minEarnings"
                                    value={formik.values.minEarnings}
                                    onChange={formik.handleChange}
                                    style={{
                                        marginBottom: '16px',
                                        width: '49%',
                                    }}
                                    isRequired={true}
                                />

                                <CustomTextField
                                    label="Max Earnings"
                                    variant="outlined"
                                    inputProps={{
                                        type: 'number',
                                    }}
                                    id="maxEarnings"
                                    name="maxEarnings"
                                    value={formik.values.maxEarnings}
                                    onChange={formik.handleChange}
                                    style={{
                                        marginBottom: '16px',
                                        width: '49%',
                                    }}
                                    isRequired={true}
                                />
                            </Box>
                            {/* Not mandatory */}
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <CustomTextField
                                    label="Online Cutoff (Mins)"
                                    variant="outlined"
                                    inputProps={{
                                        type: 'number',
                                    }}
                                    id="onlineCutoffMins"
                                    name="onlineCutoffMins"
                                    value={formik.values.onlineCutoffMins}
                                    onChange={formik.handleChange}
                                    style={{
                                        marginBottom: '16px',
                                        width: '49%',
                                    }}
                                    isRequired={false}
                                />
                                <CustomTextField
                                    label="Booking Cutoff (Mins)"
                                    variant="outlined"
                                    inputProps={{
                                        type: 'number',
                                    }}
                                    id="bookingCutoffMins"
                                    name="bookingCutoffMins"
                                    value={formik.values.bookingCutoffMins}
                                    onChange={formik.handleChange}
                                    style={{
                                        marginBottom: '16px',
                                        width: '49%',
                                    }}
                                    isRequired={false}
                                />
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    staff: state.staff,
    isEnabledXUtil: state?.user?.config?.isEnabledXUtil,
    orgId: state?.settings?.basicDetails?.id,
    isPinCodeOrg: state?.settings?.basicDetails?.vendorName == 'PINCODE',
    isAmazonOrg: state?.settings?.basicDetails?.vendorName == 'AMAZON',
});

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateShiftTiming: (data, onSuccess, onError) =>
        dispatch(createShiftTiming(data, onSuccess, onError)),
    dispatchEditShiftTiming: (data, onSuccess, onError) =>
        dispatch(editShiftTiming(data, onSuccess, onError)),
    dispatchFetchClientList: (onSuccess, onError) =>
        dispatch(fetchClientList(onSuccess, onError)),
    dispatchGetHubList: (id, onSuccess, onError) =>
        dispatch(getHubList(id, onSuccess, onError)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddShiftTimingModal);
