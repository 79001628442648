import {
    Box,
    Divider,
    Grid,
    Paper,
    Typography,
    makeStyles,
    Button,
    IconButton,
    MenuItem,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { Spinner } from '../../../components/UI';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CheckMarkGreen, CrossMarkIcon, Info } from '../../../assets/svg';
import { CustomTextField } from '../../../components/Textfield';
import { FileUploader, LightBoxImage } from '../../../components/UI';
import { MultiFileUploader } from '../../../components/UI/FileUploader/MultiFileUploader';
import {
    getSingleStaff,
    getEvDetails,
    getStaffEvHistory,
    updateEvStatus,
    captainApprove,
    vendorUpdate,
} from '../../../store/actions/staffActionCreator';
import RejectKycModal from '../../../components/Modals/RejectKycModal';
import { enqueueSnackbar } from '../../../store/actions/notificationActionCreator';
import {
    ALL_EV_STATUS,
    EV_STATUS_UI_PROPS,
    CRUD_STATUS,
    SAVE_EDIT_DISABLED_STATUS,
    TAG_BUTTON_STATUS,
    UN_TAG_BUTTON_STATUS,
    REJECT_BUTTON_STATUS,
    MANUAL_BOX_STATUS,
    MANUAL_BUTTON_STATUS,
    TITLE_BOX_NOT_VISIBLE_STATUS,
    APPROVAL,
    REQUEST,
    UN_TAG_APPROVAL,
    COMPARE_IMAGE_SECTION,
    APPROVE_BUTTON_DISABLE_STATUS,
    APPROVE_BUTTON_STATUS,
    REJECT_BUTTON_DISABLE_STATUS,
    UN_TAG_APPROVED,
} from './helper';
import StaffEvHistoryModal from '../components/staffEvHistoryModal';
import StaffEvUnTagModal from '../components/staffEvUnTagModal';
import moment from 'moment';
import { CustomDatePicker } from '../../../components/DateTimePicker';
import {
    getAllEVVendors,
    getEVVendorLocations,
} from '../../../store/actions/settingsActionCreator';
import StaffEvCompareModal from '../components/staffEvCompareModal';
import RejectEVModal from '../../../components/Modals/RejectEVModal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
const useStyles = makeStyles((theme) => ({
    container: {},
    input: {
        padding: '8px 12px !important',
    },
    dateInput: {
        padding: '12px !important',
    },
}));

export const EVehicleComp = ({
    staffInfo,
    evInfo,
    dispatchPostEvDetails,
    showMessage,
    getSingleStaff,
    dispatchGetEvDetails,
    dispatchGetEvHistory,
    dispatchGetAllEVVendors,
    dispatchApproveByCaptain,
    dispatchUpdateVendor,
    dispatchEVVendorLocations,
    permissions,
    vendorList,
    ...props
}) => {
    useEffect(() => {
        fetchEvInfo();
    }, []);

    const classes = useStyles();

    const [loading, toggleLoading] = useState(true);
    // getSingleStaff
    const [staffEvInfo, setStaffEvInfo] = useState({});
    const [vendorLocations, setVendorLocations] = useState([]);
    const [evPayload, setEvPayload] = useState({
        eventDateEpochMillis: moment().valueOf(),
    });
    const [isEvDetailsEdited, setIsEvDetailsEdited] = useState(false);
    const [evHistoryModalOpen, setEvHistoryModalOpen] = useState(false);
    const [evUnTagModalOpen, setEvUnTagModalOpen] = useState(false);
    const [evCompareModalOpen, setEvCompareModalOpen] = useState(false);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const [selectedRejectStatus, setSelectedRejectStatus] = useState(null);

    useEffect(() => {
        dispatchGetAllEVVendors({});
    }, []);

    useEffect(() => {
        setStaffEvInfo(evInfo);
        setEvPayload(
            evInfo?.evDetails ?? {
                eventDateEpochMillis: moment().valueOf(),
                vendorId: evInfo?.evDetails?.vendorId ?? null,
                vendorSelectedId: evInfo?.evDetails?.vendorId ?? null,
                cityVendorId: evInfo?.cityVendorId || '',
            },
        );
        if (!!evInfo?.evDetails?.vendorId) {
            getVendorLocations(evInfo?.evDetails?.vendorId);
        }
    }, [evInfo]);
    const verificationStatus = [
        {
            pendingTxt: 'Aadhar verification pending',
            verificationTxt: 'Aadhar verified',
            completed: evInfo?.aadharVerified ?? false,
            show: true,
        },
        {
            pendingTxt: 'EV Deposit Payment pending',
            verificationTxt: 'EV Deposit Paid',
            completed: evInfo?.evDepositPaid ?? false,
            show: true,
        },
        {
            pendingTxt: `Captain Approve pending ${
                !!evInfo?.evDetails?.captainName
                    ? `: ${evInfo?.evDetails?.captainName}`
                    : ''
            }`,
            verificationTxt: `Approved: Captain ${evInfo?.evDetails?.captainName}`,
            completed: evInfo?.captainVerified ?? false,
            show:
                (!evInfo?.captainVerified || !!evInfo?.captainVerified) &&
                !!evInfo?.evDetails?.captainName &&
                staffEvInfo?.evDetails?.status != ALL_EV_STATUS.EV_UNTAGGED,
        },
    ];
    const fetchEvInfo = async () => {
        await toggleLoading(true);
        dispatchGetEvDetails(
            {
                id: staffInfo.id,
            },
            (resp) => {
                toggleLoading(false);
            },
            (resp) => {
                toggleLoading(false);
            },
        );
    };
    const unTagEV = (payload) => {
        updateEvInfo(
            {
                ...evPayload,
                ...payload,
                status: ALL_EV_STATUS.EV_UNTAGGED,
            },
            () => {},
            () => {},
        );
    };
    const onApproveRequest = (payload) => {
        updateEvInfo(
            {
                ...evPayload,
                ...payload,
                status: ALL_EV_STATUS.EV_UN_TAG_REQUEST_APPROVED,
            },
            () => {},
            () => {},
        );
    };
    const updateEvInfo = async (payload, onSuccess, onFailure) => {
        await toggleLoading(true);
        dispatchPostEvDetails(
            staffInfo.id,
            {
                ...payload,
                currentStatus: evInfo?.evDetails?.status ?? null,
                requestId: evInfo?.evDetails?.requestId ?? null,
                eventDateEpochMillis:
                    payload?.eventDateEpochMillis ?? moment().valueOf(),
            },
            (resp) => {
                fetchEvInfo();
                !!onSuccess && onSuccess();
                toggleLoading(false);
            },
            (resp) => {
                //fetchEvInfo();
                !!onFailure && onFailure();
                toggleLoading(false);
            },
        );
    };
    const getVendorLocations = (vendorId) => {
        if (!!vendorId)
            dispatchEVVendorLocations(
                {
                    vendorId: vendorId,
                    staffId: staffInfo.id,
                },
                (res) => {
                    if (
                        res?.status?.code === 200 &&
                        !!res?.response?.locations
                    ) {
                        setVendorLocations(res.response.locations);
                    }
                },
                () => {},
            );
    };
    const checkDocumentsStatus = () => {
        if (!!evPayload && !!evPayload?.evDocuments) {
            let docs = evPayload?.evDocuments;
            if (
                !!docs?.evFrontImage &&
                !!docs?.evBackImage &&
                !!docs?.evLeftImage &&
                !!docs?.evRightImage &&
                !!docs?.evWithRider &&
                !!docs?.evLicense
            ) {
                return true;
            }
        }
        return false;
    };
    let status = !!staffEvInfo?.evDetails?.status
        ? staffEvInfo?.evDetails?.status
        : ALL_EV_STATUS.MANUAL_TAG;
    const tagButtonStatus =
        TAG_BUTTON_STATUS.includes(status) &&
        evPayload?.evNumber?.length >= 14 &&
        //evPayload?.evVehicleNumber?.length >= 5 &&
        checkDocumentsStatus();
    const unTagButtonStatus = UN_TAG_BUTTON_STATUS.includes(status);
    const rejectButtonStatus = REJECT_BUTTON_STATUS.includes(status);
    const approveButtonStatus = APPROVE_BUTTON_STATUS.includes(status);
    const rejectButtonDisableStatus =
        REJECT_BUTTON_DISABLE_STATUS.includes(status);
    const approveButtonDisableStatus =
        APPROVE_BUTTON_DISABLE_STATUS.includes(status);
    const tagged = ALL_EV_STATUS.EV_TAGGED == status;
    const tagRequested = APPROVAL.includes(status);
    const unTagRequested = UN_TAG_APPROVAL.includes(status);
    const unTagRequestAProved = UN_TAG_APPROVED.includes(status);
    const requested = REQUEST.includes(status);
    let regexPattern = /^[a-zA-Z0-9]+$/;
    const evNumberError =
            evPayload?.evNumber?.length < 14 ||
            !regexPattern?.test(evPayload?.evNumber),
        evNumberErrorMsg =
            'Chassis number must be alphanumeric, at least 14 characters, & contain no special characters!';
    const evVehicleNumberError =
            evPayload?.evVehicleNumber?.length < 5 ||
            !regexPattern?.test(evPayload?.evVehicleNumber),
        evVehicleNumberErrorMsg =
            'Vehicle number must be alphanumeric, at least 5 characters, & contain no special characters!';

    return (
        <Spinner loading={loading}>
            <Paper
                style={{ marginBottom: '16px' }}
                elevation={0}
                className={classes.container}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 700,
                                    color: '#14112d',
                                }}
                                variant="h6"
                                component="span"
                                gutterBottom
                            >
                                {'EV Details'}
                            </Typography>
                            {!!status &&
                                !TITLE_BOX_NOT_VISIBLE_STATUS.includes(
                                    status,
                                ) && (
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ marginLeft: '1rem' }}>
                                            {tagged ? (
                                                <CheckMarkGreen
                                                    style={{ width: '20px' }}
                                                />
                                            ) : (
                                                <Info
                                                    style={{
                                                        width: '20px',
                                                        color: tagRequested
                                                            ? '#FB8A06'
                                                            : unTagRequested
                                                            ? '#f44336'
                                                            : requested
                                                            ? '#43419E'
                                                            : '#000',
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <Typography
                                            style={{
                                                fontSize: '18px',
                                                fontWeight: 700,
                                                marginLeft: 5,
                                                color: tagged
                                                    ? '#4caf50'
                                                    : unTagRequested
                                                    ? '#f44336'
                                                    : tagRequested
                                                    ? '#FB8A06'
                                                    : requested
                                                    ? '#43419E'
                                                    : '#000',
                                            }}
                                            variant="h6"
                                            component="span"
                                            gutterBottom
                                        >
                                            {tagged
                                                ? 'Tagged to EV'
                                                : tagRequested
                                                ? 'Approval Pending'
                                                : unTagRequested
                                                ? 'Requested for UnTagging'
                                                : unTagRequestAProved
                                                ? 'Untag Request Approved'
                                                : requested
                                                ? 'Requested for Rent'
                                                : 'Tag EV manually'}
                                        </Typography>
                                    </div>
                                )}
                            <div style={{ flexGrow: 1 }}>
                                <Divider
                                    style={{ width: '98%', marginLeft: 10 }}
                                />
                            </div>
                        </Box>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                direction: 'row',
                                flexDirection: 'row',
                            }}
                        >
                            {verificationStatus?.map((status, i) => {
                                return !!status?.show ? (
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            direction: 'row',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {i != 0 && (
                                            <Box
                                                style={{
                                                    width: '2px',
                                                    height: '24px',
                                                    backgroundColor: '#000',
                                                    opacity: 0.16,
                                                    marginLeft: '8px',
                                                    marginRight: '8px',
                                                    borderRadius: '12px',
                                                }}
                                            ></Box>
                                        )}
                                        {!!status?.completed ? (
                                            <CheckCircleIcon
                                                style={{
                                                    width: '16px',
                                                    color: '#0AA061',
                                                    marginRight: '8px',
                                                }}
                                            />
                                        ) : (
                                            <ErrorIcon
                                                style={{
                                                    width: '16px',
                                                    color: '#FB8A06',
                                                    marginRight: '8px',
                                                }}
                                            />
                                        )}
                                        <Typography
                                            style={{
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontWeight: 600,
                                                color: '#14112d',
                                                marginBottom: 0,
                                            }}
                                            variant="body2"
                                            component="span"
                                            gutterBottom
                                        >
                                            {!status?.completed
                                                ? status?.pendingTxt
                                                : status?.verificationTxt}
                                        </Typography>
                                    </Box>
                                ) : null;
                            })}
                        </Box>
                    </Grid>
                    <Grid container spacing={2} direction="row">
                        <Grid
                            container
                            spacing={2}
                            item
                            xs={!!staffEvInfo?.historyAvailable ? 9 : 12}
                            direction="row"
                        >
                            {MANUAL_BOX_STATUS.includes(status) && (
                                <Grid item xs={12}>
                                    {!!EV_STATUS_UI_PROPS[status]?.title && (
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                direction: 'column',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    color: '#14112d',
                                                }}
                                                variant="body2"
                                                component="span"
                                                gutterBottom
                                            >
                                                {EV_STATUS_UI_PROPS[status]
                                                    ?.title ?? ''}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    color: '#14112d',
                                                }}
                                                variant="body2"
                                                component="span"
                                                gutterBottom
                                            >
                                                {EV_STATUS_UI_PROPS[status]
                                                    ?.subTitle ?? ''}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            direction: 'row',
                                            flexDirection: 'row',
                                            marginTop: '24px',
                                        }}
                                    >
                                        {MANUAL_BUTTON_STATUS.includes(
                                            status,
                                        ) && (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={(e) => {
                                                    setStaffEvInfo((prev) => ({
                                                        ...prev,
                                                        evDetails: {
                                                            ...prev?.evDetails,
                                                            status: ALL_EV_STATUS.MANUAL_INFO,
                                                        },
                                                    }));
                                                    setEvPayload({
                                                        eventDateEpochMillis:
                                                            moment().valueOf(),
                                                    });
                                                }}
                                                size="small"
                                                style={{
                                                    border: '1px solid #43419E',
                                                    color: '#43419E',
                                                }}
                                                disabled={!permissions.create}
                                                disableElevation
                                            >
                                                Manually tag EV
                                            </Button>
                                        )}
                                        {status ==
                                            ALL_EV_STATUS.EV_REQUESTED && (
                                            <>
                                                {!!evInfo?.captainVerified && (
                                                    <>
                                                        <Grid item xs={4}>
                                                            <CustomTextField
                                                                label="Vendor*"
                                                                select
                                                                id="vendorSelectedId"
                                                                name="vendorSelectedId"
                                                                value={
                                                                    evPayload?.vendorSelectedId ??
                                                                    ''
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    setEvPayload(
                                                                        (
                                                                            prev,
                                                                        ) => {
                                                                            return {
                                                                                ...prev,
                                                                                vendorSelectedId:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                cityVendorId:
                                                                                    '',
                                                                            };
                                                                        },
                                                                    );
                                                                    getVendorLocations(
                                                                        e.target
                                                                            .value,
                                                                    );
                                                                }}
                                                            >
                                                                {vendorList?.length ===
                                                                    0 && (
                                                                    <MenuItem
                                                                        value={
                                                                            undefined
                                                                        }
                                                                    >
                                                                        No Data
                                                                        found
                                                                    </MenuItem>
                                                                )}
                                                                {vendorList?.map(
                                                                    (
                                                                        vendor,
                                                                    ) => (
                                                                        <MenuItem
                                                                            value={
                                                                                vendor.id
                                                                            }
                                                                        >
                                                                            {
                                                                                vendor.name
                                                                            }
                                                                        </MenuItem>
                                                                    ),
                                                                )}
                                                            </CustomTextField>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <Box ml={'16px'}>
                                                                <CustomTextField
                                                                    label="Vendor Locations*"
                                                                    select
                                                                    id="cityVendorId"
                                                                    name="cityVendorId"
                                                                    value={
                                                                        evPayload?.cityVendorId ??
                                                                        ''
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        setEvPayload(
                                                                            (
                                                                                prev,
                                                                            ) => {
                                                                                return {
                                                                                    ...prev,
                                                                                    cityVendorId:
                                                                                        e
                                                                                            .target
                                                                                            .value,
                                                                                };
                                                                            },
                                                                        );
                                                                    }}
                                                                >
                                                                    {vendorLocations?.length ===
                                                                        0 && (
                                                                        <MenuItem
                                                                            value={
                                                                                undefined
                                                                            }
                                                                        >
                                                                            No
                                                                            Data
                                                                            found
                                                                        </MenuItem>
                                                                    )}
                                                                    {vendorLocations?.map(
                                                                        (
                                                                            vendorLoc,
                                                                        ) => (
                                                                            <MenuItem
                                                                                value={
                                                                                    vendorLoc.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    vendorLoc.address
                                                                                }
                                                                            </MenuItem>
                                                                        ),
                                                                    )}
                                                                </CustomTextField>
                                                            </Box>
                                                        </Grid>
                                                    </>
                                                )}
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        if (
                                                            !!evInfo?.captainVerified
                                                        ) {
                                                            updateEvInfo(
                                                                {
                                                                    ...evPayload,
                                                                    status: ALL_EV_STATUS.EV_REQUEST_APPROVED,
                                                                    vendorId:
                                                                        evPayload?.vendorSelectedId ??
                                                                        evPayload?.vendorId ??
                                                                        evInfo
                                                                            ?.evDetails
                                                                            ?.vendorId,
                                                                },
                                                                () => {},
                                                                () => {},
                                                            );
                                                        } else {
                                                            dispatchApproveByCaptain(
                                                                staffInfo.id,
                                                                {},
                                                                () => {
                                                                    fetchEvInfo();
                                                                },
                                                                () => {},
                                                            );
                                                        }
                                                    }}
                                                    size="small"
                                                    style={{
                                                        borderRadius: '8px',
                                                        padding: '7px 16px',
                                                        marginLeft: '16px',
                                                        ...(vendorLocations?.length >
                                                            0 &&
                                                        !!evInfo?.captainVerified &&
                                                        !!evPayload.cityVendorId
                                                            ? {
                                                                  backgroundColor:
                                                                      '#4CAF50',
                                                                  border: '1px solid #4CAF50',
                                                                  color: '#FFF',
                                                              }
                                                            : !!evInfo?.captainVerified &&
                                                              (!evPayload.cityVendorId ||
                                                                  vendorLocations?.length ===
                                                                      0)
                                                            ? {
                                                                  backgroundColor:
                                                                      'rgba(0, 0, 0, 0.38)',
                                                                  color: '#FFF',
                                                              }
                                                            : {}),
                                                    }}
                                                    disabled={
                                                        !!evInfo?.captainVerified &&
                                                        (!evPayload.cityVendorId ||
                                                            vendorLocations?.length ===
                                                                0)
                                                    }
                                                    disableElevation
                                                >
                                                    Approve
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        setSelectedRejectStatus(
                                                            ALL_EV_STATUS.EV_REQUEST_REJECTED,
                                                        );
                                                        setRejectModalOpen(
                                                            true,
                                                        );
                                                        /**updateEvInfo(
                                                            {
                                                                ...evPayload,
                                                                status: ALL_EV_STATUS.EV_REQUEST_REJECTED,
                                                            },
                                                            () => {},
                                                            () => {},
                                                        );*/
                                                    }}
                                                    style={{
                                                        border: '1px solid #f44336',
                                                        color: '#f44336',
                                                        marginLeft: '16px',
                                                        borderRadius: '8px',
                                                        padding: '7px 16px',
                                                    }}
                                                    disabled={
                                                        !permissions.create
                                                    }
                                                    disableElevation
                                                >
                                                    Reject
                                                </Button>
                                            </>
                                        )}
                                    </Box>
                                </Grid>
                            )}
                            {CRUD_STATUS.includes(status) && (
                                <>
                                    {status ==
                                        ALL_EV_STATUS.EV_REQUEST_APPROVED &&
                                        !!evInfo?.captainVerified && (
                                            <Grid item xs={12}>
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'flex-start',
                                                        direction: 'row',
                                                        flexDirection: 'row',
                                                        marginTop: '24px',
                                                    }}
                                                >
                                                    <Grid item xs={4}>
                                                        <CustomTextField
                                                            label="Vendor*"
                                                            select
                                                            id="vendorSelectedId"
                                                            name="vendorSelectedId"
                                                            value={
                                                                evPayload?.vendorSelectedId ??
                                                                evInfo
                                                                    ?.evDetails
                                                                    ?.vendorId ??
                                                                ''
                                                            }
                                                            onChange={(e) => {
                                                                setEvPayload(
                                                                    (prev) => {
                                                                        return {
                                                                            ...prev,
                                                                            vendorSelectedId:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            cityVendorId:
                                                                                '',
                                                                        };
                                                                    },
                                                                );
                                                                getVendorLocations(
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                        >
                                                            {vendorList?.length ===
                                                                0 && (
                                                                <MenuItem
                                                                    value={
                                                                        undefined
                                                                    }
                                                                >
                                                                    No Data
                                                                    found
                                                                </MenuItem>
                                                            )}
                                                            {vendorList?.map(
                                                                (vendor) => (
                                                                    <MenuItem
                                                                        value={
                                                                            vendor.id
                                                                        }
                                                                    >
                                                                        {
                                                                            vendor.name
                                                                        }
                                                                    </MenuItem>
                                                                ),
                                                            )}
                                                        </CustomTextField>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Box ml={'16px'}>
                                                            <CustomTextField
                                                                label="Vendor Locations*"
                                                                select
                                                                id="cityVendorId"
                                                                name="cityVendorId"
                                                                value={
                                                                    evPayload?.cityVendorId ??
                                                                    ''
                                                                }
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    setEvPayload(
                                                                        (
                                                                            prev,
                                                                        ) => {
                                                                            return {
                                                                                ...prev,
                                                                                cityVendorId:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            };
                                                                        },
                                                                    );
                                                                }}
                                                            >
                                                                {vendorLocations?.length ===
                                                                    0 && (
                                                                    <MenuItem
                                                                        value={
                                                                            undefined
                                                                        }
                                                                    >
                                                                        No Data
                                                                        found
                                                                    </MenuItem>
                                                                )}
                                                                {vendorLocations?.map(
                                                                    (
                                                                        vendorLoc,
                                                                    ) => (
                                                                        <MenuItem
                                                                            value={
                                                                                vendorLoc.id
                                                                            }
                                                                        >
                                                                            {
                                                                                vendorLoc.address
                                                                            }
                                                                        </MenuItem>
                                                                    ),
                                                                )}
                                                            </CustomTextField>
                                                        </Box>
                                                    </Grid>

                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) => {
                                                            dispatchUpdateVendor(
                                                                staffInfo.id,
                                                                {
                                                                    cityVendorId:
                                                                        evPayload?.cityVendorId,
                                                                },
                                                                () => {
                                                                    fetchEvInfo();
                                                                },
                                                                () => {},
                                                            );
                                                        }}
                                                        size="small"
                                                        style={{
                                                            borderRadius: '8px',
                                                            padding: '7px 16px',
                                                            marginLeft: '16px',
                                                            ...(vendorLocations?.length >
                                                                0 &&
                                                            !!evPayload.vendorSelectedId &&
                                                            !!evPayload.cityVendorId &&
                                                            evPayload?.cityVendorId !=
                                                                evInfo
                                                                    ?.evDetails
                                                                    ?.cityVendorId
                                                                ? {
                                                                      backgroundColor:
                                                                          '#4CAF50',
                                                                      border: '1px solid #4CAF50',
                                                                      color: '#FFF',
                                                                  }
                                                                : {
                                                                      backgroundColor:
                                                                          'rgba(0, 0, 0, 0.38)',
                                                                      color: '#FFF',
                                                                  }),
                                                        }}
                                                        disabled={
                                                            vendorLocations?.length ===
                                                                0 ||
                                                            !evPayload.vendorSelectedId ||
                                                            !evPayload.cityVendorId ||
                                                            (evPayload.vendorSelectedId &&
                                                                evPayload?.cityVendorId ==
                                                                    evInfo
                                                                        ?.evDetails
                                                                        ?.cityVendorId)
                                                        }
                                                        disableElevation
                                                    >
                                                        Update vendor
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        )}
                                    <Grid item xs={12}>
                                        <Box
                                            mt={'16px'}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    color: '#14112d',
                                                }}
                                                variant="body2"
                                                component="span"
                                                gutterBottom
                                            >
                                                {'EV Number'}
                                            </Typography>
                                            <CustomTextField
                                                value={
                                                    evPayload?.evVehicleNumber
                                                }
                                                classes={{}}
                                                required={true}
                                                onChange={(e) => {
                                                    setEvPayload((prev) => {
                                                        return {
                                                            ...prev,
                                                            evVehicleNumber:
                                                                e.target.value?.trim(),
                                                        };
                                                    });
                                                    setIsEvDetailsEdited(true);
                                                }}
                                                variant="outlined"
                                                style={{
                                                    width: '13%',
                                                    marginLeft: 20,
                                                    marginBottom: 0,
                                                }}
                                                size="small"
                                                disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                    status,
                                                )}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.input,
                                                    },
                                                }}
                                                error={evVehicleNumberError}
                                                helperText={
                                                    !!evVehicleNumberError
                                                        ? evVehicleNumberErrorMsg
                                                        : ''
                                                }
                                            />
                                            <Typography
                                                style={{
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    color: '#14112d',
                                                    marginLeft: 20,
                                                }}
                                                variant="body2"
                                                component="span"
                                                gutterBottom
                                            >
                                                {'EV Chassis Number *'}
                                            </Typography>
                                            <CustomTextField
                                                value={evPayload?.evNumber}
                                                classes={{}}
                                                onChange={(e) => {
                                                    setEvPayload((prev) => {
                                                        return {
                                                            ...prev,
                                                            evNumber:
                                                                e.target.value?.trim(),
                                                        };
                                                    });
                                                    setIsEvDetailsEdited(true);
                                                }}
                                                variant="outlined"
                                                style={{
                                                    width: '18%',
                                                    marginLeft: 20,
                                                    marginBottom: 0,
                                                }}
                                                size="small"
                                                disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                    status,
                                                )}
                                                InputProps={{
                                                    classes: {
                                                        input: classes.input,
                                                    },
                                                }}
                                                error={evNumberError}
                                                helperText={
                                                    !!evNumberError
                                                        ? evNumberErrorMsg
                                                        : ''
                                                }
                                            />

                                            {tagButtonStatus && (
                                                <Box
                                                    style={{
                                                        width: '15%',
                                                    }}
                                                >
                                                    <CustomTextField
                                                        label="Rented Date"
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                input: classes.dateInput,
                                                            },
                                                            endAdornment: (
                                                                <IconButton
                                                                    style={{
                                                                        padding: 0,
                                                                        margin: 0,
                                                                    }}
                                                                    className={
                                                                        'inputbutton'
                                                                    }
                                                                    onClick={(
                                                                        e,
                                                                    ) =>
                                                                        setDatePickerOpen(
                                                                            true,
                                                                        )
                                                                    }
                                                                >
                                                                    <EventIcon
                                                                        size="small"
                                                                        style={{
                                                                            padding: 0,
                                                                            margin: 0,
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            ),
                                                        }}
                                                        onClick={(e) =>
                                                            setDatePickerOpen(
                                                                true,
                                                            )
                                                        }
                                                        id="eventDateEpochMillis"
                                                        name="eventDateEpochMillis"
                                                        value={moment(
                                                            evPayload?.eventDateEpochMillis,
                                                        ).format('yyyy-MM-DD')}
                                                        variant="outlined"
                                                        style={{
                                                            padding: 0,
                                                            marginLeft: '8px',
                                                        }}
                                                        isRequired={true}
                                                    />
                                                </Box>
                                            )}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{
                                                    marginLeft: 20,
                                                    marginRight: 20,
                                                }}
                                                disabled={
                                                    SAVE_EDIT_DISABLED_STATUS.includes(
                                                        status,
                                                    ) ||
                                                    !isEvDetailsEdited ||
                                                    !evPayload?.evNumber ||
                                                    evPayload?.evNumber
                                                        ?.length < 14 ||
                                                    !checkDocumentsStatus()
                                                }
                                                disableElevation
                                                onClick={async () => {
                                                    if (
                                                        !evPayload?.evNumber.trim()
                                                    ) {
                                                        showMessage(
                                                            'Invalid EV Number',
                                                            'error',
                                                        );
                                                        return;
                                                    }
                                                    updateEvInfo(
                                                        {
                                                            ...evPayload,
                                                            status: ALL_EV_STATUS.EV_INFO_SAVED,
                                                        },
                                                        () => {
                                                            setIsEvDetailsEdited(
                                                                false,
                                                            );
                                                        },
                                                        () => {},
                                                    );
                                                }}
                                            >
                                                Save
                                            </Button>
                                            {approveButtonStatus && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={(e) => {
                                                        updateEvInfo(
                                                            {
                                                                ...evPayload,
                                                                status:
                                                                    status ==
                                                                    ALL_EV_STATUS.UNTAG_REQUESTED
                                                                        ? ALL_EV_STATUS.UNTAG_REQUEST_APPROVED
                                                                        : status ==
                                                                          ALL_EV_STATUS.EV_TAG_REQUESTED
                                                                        ? ALL_EV_STATUS.EV_TAG_REQUEST_APPROVED
                                                                        : ALL_EV_STATUS.EV_UN_TAG_REQUEST_APPROVED,
                                                            },
                                                            () => {},
                                                            () => {},
                                                        );
                                                    }}
                                                    size="small"
                                                    style={{
                                                        borderRadius: '8px',
                                                        padding: '7px 16px',
                                                        marginRight: '16px',
                                                        ...(checkDocumentsStatus() &&
                                                        !rejectButtonDisableStatus
                                                            ? {
                                                                  backgroundColor:
                                                                      '#4CAF50',
                                                                  border: '1px solid #4CAF50',
                                                                  color: '#FFF',
                                                              }
                                                            : {}),
                                                    }}
                                                    disabled={
                                                        !checkDocumentsStatus() ||
                                                        !!approveButtonDisableStatus
                                                    }
                                                    disableElevation
                                                >
                                                    Approve
                                                </Button>
                                            )}
                                            {tagButtonStatus && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    style={
                                                        !tagButtonStatus
                                                            ? {
                                                                  marginRight: 20,
                                                              }
                                                            : {
                                                                  marginRight: 20,
                                                                  backgroundColor:
                                                                      '#4caf50',
                                                                  color: '#FFF',
                                                                  //   border: '1px solid #4caf50',
                                                                  fontWeight: 700,
                                                              }
                                                    }
                                                    onClick={(e) => {
                                                        updateEvInfo(
                                                            {
                                                                ...evPayload,
                                                                status: ALL_EV_STATUS.EV_TAGGED,
                                                            },
                                                            () => {
                                                                setIsEvDetailsEdited(
                                                                    false,
                                                                );
                                                            },
                                                            () => {},
                                                        );
                                                    }}
                                                    disabled={!tagButtonStatus}
                                                    disableElevation
                                                >
                                                    Tag EV
                                                </Button>
                                            )}
                                            {unTagButtonStatus && (
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    style={
                                                        unTagButtonStatus
                                                            ? {
                                                                  marginRight: 20,
                                                                  backgroundColor:
                                                                      '#f44336',
                                                                  //   border: '1px solid #f44336',
                                                                  color: '#fff',
                                                                  fontWeight: 600,
                                                              }
                                                            : {}
                                                    }
                                                    disabled={
                                                        !unTagButtonStatus
                                                    }
                                                    disableElevation
                                                    onClick={(e) => {
                                                        setEvUnTagModalOpen(
                                                            true,
                                                        );
                                                    }}
                                                >
                                                    Un-Tag EV
                                                </Button>
                                            )}
                                            {rejectButtonStatus && (
                                                <Button
                                                    variant="outlined"
                                                    style={
                                                        rejectButtonStatus &&
                                                        !rejectButtonDisableStatus
                                                            ? {
                                                                  marginRight: 20,
                                                                  backgroundColor:
                                                                      '#fff',
                                                                  border: '1px solid #f44336',
                                                                  color: '#f44336',
                                                                  fontWeight: 600,
                                                              }
                                                            : {}
                                                    }
                                                    disabled={
                                                        !rejectButtonStatus ||
                                                        !!rejectButtonDisableStatus
                                                    }
                                                    disableElevation
                                                    onClick={(e) => {
                                                        setSelectedRejectStatus(
                                                            status ==
                                                                ALL_EV_STATUS.UNTAG_REQUESTED
                                                                ? ALL_EV_STATUS.UNTAG_REQUEST_REJECTED
                                                                : status ==
                                                                  ALL_EV_STATUS.EV_UNTAG_REQUESTED
                                                                ? ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED
                                                                : ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
                                                        );
                                                        setRejectModalOpen(
                                                            true,
                                                        );
                                                        /**updateEvInfo(
                                                            {
                                                                ...evPayload,
                                                                status:
                                                                    status ==
                                                                    ALL_EV_STATUS.EV_UNTAG_REQUESTED
                                                                        ? ALL_EV_STATUS.EV_UNTAG_REQUEST_REJECTED
                                                                        : ALL_EV_STATUS.EV_TAG_REQUEST_REJECTED,
                                                            },
                                                            () => {},
                                                            () => {},
                                                        );*/
                                                    }}
                                                >
                                                    Reject
                                                </Button>
                                            )}
                                        </Box>
                                    </Grid>

                                    {/* <Grid item sm={6} xs={12}>
                                        <FileUploader
                                            label={'Upload photo (EV)'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={evPayload?.evImage}
                                            placeholder={'Upload photo (EV)'}
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evImage: e,
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evImage: null,
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12}>
                                        <FileUploader
                                            label={
                                                'Upload photo (License plate)'
                                            }
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={evPayload?.evLicense}
                                            placeholder={
                                                'Upload photo (License plate)'
                                            }
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);

                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evLicense: e,
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evLicense: null,
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid> */}
                                </>
                            )}
                        </Grid>
                        {!!staffEvInfo?.historyAvailable && (
                            <Grid item xs={3}>
                                <Box
                                    onClick={(e) => {
                                        setEvHistoryModalOpen(true);
                                    }}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        direction: 'row',
                                        flexDirection: 'row',
                                        borderRadius: '8px',
                                        backgroundColor: '#F5F5F5',
                                        cursor: 'pointer',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontFamily: 'Nunito',
                                            fontSize: '14px',
                                            fontWeight: 400,
                                            color: '#14112D',
                                            marginBottom: 0,
                                            padding: '6px 24px',
                                        }}
                                        variant="body2"
                                        component="span"
                                        gutterBottom
                                    >
                                        {'Last Rent paid on : '}
                                        <strong>
                                            {staffEvInfo?.lastRentPaidOn
                                                ? moment(
                                                      staffEvInfo?.lastRentPaidOn,
                                                  ).format(
                                                      'DD MMMM YYYY, h:mm:ss a',
                                                  )
                                                : '-/-'}
                                        </strong>
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontFamily: 'Nunito',
                                            fontSize: '14px',
                                            fontWeight: 700,
                                            color: '#FFF',
                                            padding: '7px 24px',
                                            backgroundColor: '#43419e',
                                            borderTopRightRadius: '8px',
                                            borderBottomRightRadius: '8px',
                                            marginBottom: 0,
                                        }}
                                        variant="body2"
                                        component="span"
                                        gutterBottom
                                    >
                                        {'View History'}
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        {COMPARE_IMAGE_SECTION.includes(status) &&
                            !!staffEvInfo?.evDetails?.untagEvDocuments && (
                                <Grid
                                    container
                                    spacing={2}
                                    item
                                    xs={12}
                                    direction="row"
                                >
                                    <Grid item xs={12}>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                border: '1px solid #EBEDF8',
                                                padding: '12px 24px',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                        color: '#14112d',
                                                    }}
                                                    variant="body2"
                                                    component="p"
                                                    gutterBottom
                                                >
                                                    {'Compare images'}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                        color: 'rgba(20, 17, 45, 0.50)',
                                                    }}
                                                    variant="body2"
                                                    component="p"
                                                    gutterBottom
                                                >
                                                    {
                                                        'View images taken before to images taken recently on untag request'
                                                    }
                                                </Typography>
                                            </Box>
                                            <Button
                                                variant="contained"
                                                size="medium"
                                                style={{
                                                    backgroundColor: '#43419E',
                                                    //   border: '1px solid #43419E',
                                                    color: '#fff',
                                                    fontWeight: 600,
                                                }}
                                                disabled={false}
                                                disableElevation
                                                onClick={(e) => {
                                                    setEvCompareModalOpen(true);
                                                }}
                                            >
                                                View Images
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                            direction="row"
                        >
                            {CRUD_STATUS.includes(status) && (
                                <>
                                    <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            download={true}
                                            label={'Picture ( EV FRONT )'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments
                                                    ?.evFrontImage
                                            }
                                            placeholder={'Picture ( EV FRONT )'}
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evFrontImage: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evFrontImage: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            download={true}
                                            label={'Picture ( EV BACK )'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments
                                                    ?.evBackImage
                                            }
                                            placeholder={'Picture ( EV BACK )'}
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);

                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evBackImage: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evBackImage: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            download={true}
                                            label={'Picture ( EV LEFT )'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments
                                                    ?.evLeftImage
                                            }
                                            placeholder={'Picture ( EV LEFT )'}
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evLeftImage: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evLeftImage: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            download={true}
                                            label={'Picture ( EV RIGHT )'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments
                                                    ?.evRightImage
                                            }
                                            placeholder={'Picture ( EV RIGHT )'}
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);

                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evRightImage: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evRightImage: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            download={true}
                                            label={'Picture ( Rider with EV )'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments
                                                    ?.evWithRider
                                            }
                                            placeholder={
                                                'Picture ( Rider with EV )'
                                            }
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evWithRider: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evWithRider: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            label={'EV Video ( 360)'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments?.evVideo
                                            }
                                            placeholder={'EV Video ( 360)'}
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evVideo: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evVideo: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid> */}
                                    <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            download={true}
                                            label={'EV (License plate)'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments
                                                    ?.evLicense
                                            }
                                            placeholder={'EV (License plate)'}
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);

                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evLicense: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evLicense: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            download={true}
                                            label={'EV (Chassis Number)'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments
                                                    ?.evChassis
                                            }
                                            placeholder={'EV (Chassis Number)'}
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);

                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evChassis: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evChassis: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <FileUploader
                                            download={true}
                                            label={'EV (Signed Agreement)'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments
                                                    ?.evAgreement
                                            }
                                            placeholder={
                                                'EV (Signed Agreement)'
                                            }
                                            onFileUploaded={(e) => {
                                                setIsEvDetailsEdited(true);

                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evAgreement: e,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(e) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evAgreement: null,
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item sm={3} xs={12}>
                                        <MultiFileUploader
                                            label={'Other Ev (Pictures)'}
                                            labelStyles={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                            }}
                                            multiple={true}
                                            disabled={SAVE_EDIT_DISABLED_STATUS.includes(
                                                status,
                                            )}
                                            defaultValue={
                                                evPayload?.evDocuments?.evOthers
                                            }
                                            placeholder={'Other Ev (Pictures)'}
                                            onFileUploaded={(val) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evOthers: val,
                                                        },
                                                    };
                                                });
                                            }}
                                            onRemoved={(val) => {
                                                setIsEvDetailsEdited(true);
                                                setEvPayload((prev) => {
                                                    return {
                                                        ...prev,
                                                        evDocuments: {
                                                            ...prev?.evDocuments,
                                                            evOthers: val ?? [],
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {evHistoryModalOpen && (
                    <StaffEvHistoryModal
                        open={evHistoryModalOpen}
                        staffInfo={staffInfo}
                        onClose={() => {
                            setEvHistoryModalOpen(false);
                        }}
                    />
                )}
                {evUnTagModalOpen && (
                    <StaffEvUnTagModal
                        open={evUnTagModalOpen}
                        staffInfo={staffInfo}
                        evInfo={evInfo}
                        onUnTag={unTagEV}
                        onClose={() => {
                            setEvUnTagModalOpen(false);
                        }}
                    />
                )}
                {evCompareModalOpen && (
                    <StaffEvCompareModal
                        open={evCompareModalOpen}
                        staffInfo={staffInfo}
                        evInfo={evInfo}
                        onUnTag={onApproveRequest}
                        onClose={() => {
                            setEvCompareModalOpen(false);
                        }}
                    />
                )}
                {datePickerOpen && (
                    <CustomDatePicker
                        open={datePickerOpen}
                        onOpen={() => setDatePickerOpen(true)}
                        onClose={() => setDatePickerOpen(false)}
                        format="d MMM yyyy"
                        minDate={moment().valueOf()}
                        value={evPayload?.eventDateEpochMillis}
                        onChange={(e) => {
                            setEvPayload((prev) => {
                                return {
                                    ...prev,
                                    eventDateEpochMillis: moment(e).valueOf(),
                                };
                            });
                        }}
                    />
                )}
                {rejectModalOpen && (
                    <RejectEVModal
                        open={rejectModalOpen}
                        onClose={() => {
                            setRejectModalOpen(false);
                            setSelectedRejectStatus(null);
                        }}
                        onConfirm={(remarks) => {
                            updateEvInfo(
                                {
                                    ...evPayload,
                                    remarks: remarks,
                                    status: selectedRejectStatus,
                                },
                                () => {},
                                () => {},
                            );
                            setRejectModalOpen(false);
                            setSelectedRejectStatus(null);
                        }}
                        data={selectedRejectStatus}
                        evInfo={evInfo}
                    />
                )}
            </Paper>
        </Spinner>
    );
};

const mapStateToProps = (state) => ({
    evInfo: state.staff?.evInfo,
    vendorList: state.settings?.evVendorList ?? [],
});

const mapDispatchToProps = (dispatch) => ({
    getSingleStaff: (payload, onSuccess, onError) =>
        dispatch(getSingleStaff(payload, onSuccess, onError)),
    dispatchGetEvHistory: (id, data, payload, onSuccess, onError) =>
        dispatch(getStaffEvHistory(id, data, payload, onSuccess, onError)),
    dispatchGetEvDetails: (payload, onSuccess, onError) =>
        dispatch(getEvDetails(payload, onSuccess, onError)),
    dispatchPostEvDetails: (id, payload, onSuccess, onError) =>
        dispatch(updateEvStatus(id, payload, onSuccess, onError)),
    dispatchApproveByCaptain: (id, payload, onSuccess, onError) =>
        dispatch(captainApprove(id, payload, onSuccess, onError)),
    dispatchUpdateVendor: (id, payload, onSuccess, onError) =>
        dispatch(vendorUpdate(id, payload, onSuccess, onError)),
    dispatchGetAllEVVendors: (payload, onSuccess, onError) =>
        dispatch(getAllEVVendors(payload, onSuccess, onError)),
    dispatchEVVendorLocations: (payload, onSuccess, onError) =>
        dispatch(getEVVendorLocations(payload, onSuccess, onError)),
    showMessage: (msg, type) =>
        dispatch(
            enqueueSnackbar({
                message: msg,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: type,
                },
            }),
        ),
    // dispatchDeleteImageAttachment: (id, onSuccess, onError) =>
    //     dispatch(deleteImageAttachment(id, onSuccess, onError)),
});
export const EVehicle = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EVehicleComp);
