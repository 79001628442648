import moment from 'moment';
import { enqueueSnackbar } from '../store/actions/notificationActionCreator';
import store from '../store/configureStore';
import { ScreenActionSet, ScreentPermissionSet } from './permissions';

export const ConsoleAction = (isDisable) => {
    if (isDisable)
        Object.keys(console).forEach((key) => {
            console[key] = () => {};
        });
};

export function isVideoURL(url) {
    // List of common video file extensions
    const videoExtensions = [
        '.mp4',
        '.avi',
        '.mkv',
        '.mov',
        '.wmv',
        '.flv',
        '.webm',
    ];

    // Convert the URL to lowercase for case-insensitive matching
    const lowercaseURL = url.toLowerCase();

    // Check if the URL ends with any of the video extensions
    for (const extension of videoExtensions) {
        if (lowercaseURL.endsWith(extension)) {
            return true; // URL points to a video
        }
    }

    return false; // URL is not a video
}

export function checkHttpStatus(status, hideNotify) {
    switch (status?.code) {
        case 403:
        case 400:
        case 429:
            if (!hideNotify) {
                store.dispatch(
                    enqueueSnackbar({
                        message: `${status?.message}`,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                        },
                    }),
                );
            }
            return false;
        case 401:
            if (!hideNotify)
                store.dispatch(
                    enqueueSnackbar({
                        message: `${status?.message}`,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                        },
                    }),
                );
            // AuthenticationService.logoutUser();
            logout();
            return false;
        case 501:
            // store.dispatch(
            //     enqueueSnackbar({
            //         message: `${status?.message}`,
            //         options: {
            //             key: new Date().getTime() + Math.random(),
            //             variant: 'error',
            //         },
            //     }),
            // );
            // AuthenticationService.logoutUser();
            return false;
        case 500:
        case 503:
            if (!hideNotify)
                store.dispatch(
                    enqueueSnackbar({
                        message: `${status?.message}`,
                        options: {
                            key: new Date().getTime() + Math.random(),
                            variant: 'error',
                        },
                    }),
                );
            return false;
        case 200:
        case 201:
        case 202:
            return true;
        default:
            return false;
    }
}

export const GetInitalLetters = (str) => {
    if (!str) return null;
    const strArr = str.split(' ');
    if (strArr.length === 1) {
        return `${strArr[0][0]?.toUpperCase()}`;
    }
    return `${strArr[0][0]?.toUpperCase()}${strArr[
        strArr.length - 1
    ][0]?.toUpperCase()}`;
};

/**
 *
 * @param {*} num
 * @param {
 *    maximumFractionDigits: number,
 *    style: type (default: 'currency'),
 *    currency: currency code (default: 'INR')
 * } opt
 */
export function formatCurrency(num, opt = null) {
    let options = {
        style: 'currency',
        currency: 'INR',
    };
    if (opt) {
        options = {
            ...options,
            ...opt,
        };
    }
    if (!num) num = 0;
    return num.toLocaleString('en-IN', options);
}

export function getLoginUser() {
    return localStorage.getItem('USER_INFO')
        ? JSON.parse(window.atob(localStorage.getItem('USER_INFO')))
        : '';
}
export function getLoginOrg() {
    return localStorage.getItem('ORG_INFO')
        ? JSON.parse(window.atob(localStorage.getItem('ORG_INFO')))
        : '';
}
export function getUserToken() {
    return localStorage.getItem('USER_INFO')
        ? JSON.parse(window.atob(localStorage.getItem('USER_INFO')))?.token
        : '';
}
export function getStoreState() {
    return localStorage.getItem('state')
        ? JSON.parse(window.atob(localStorage.getItem('state')))?.token
        : '';
}

export function logout() {
    localStorage.clear();
    window.location.href = '/';
}

export const dateTimeToMilliseconds = (date, time) => {
    console.log('date', date);
    console.log('time', time);
    return moment(moment(date).format('YYYY-MM-DD'))
        .set('hour', moment(time).format('H'))
        .set('minute', moment(time).format('mm'))
        .valueOf();
};

export const capitalize = (str) => {
    return str[0].toUpperCase() + str.substring(1, str.length);
};

export function getFileExtension(filename) {
    // get file extension
    const extension = filename.split('.').pop();
    return extension;
}

export function isEmpty(obj) {
    if (typeof obj === 'number' || typeof obj === 'boolean') return false;
    if (obj == null) return true;
    if (obj instanceof Date) {
        return isNaN(obj);
    }
    if (obj instanceof Set) {
        return obj.size === 0;
    }
    //if (typeof obj[index] === "undefined" && obj[index] === null) return true;
    if (obj.length === 0) return true;
    if (obj.length > 0) return false;
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }
    return true;
}

export function findArrayObjectIndex(key, value, array, path, dataType) {
    if (!isEmpty(array)) {
        for (var arrayIndex in array) {
            var a = array[arrayIndex];
            if (path) {
                for (var i = 0; i < path.length; i++) {
                    if (a && typeof a == 'object') {
                        a = a[path[i]];
                    }
                }
            }
            if (dataType == 'date') {
                console.log(
                    '(new Date(a[key])).getTime()',
                    new Date(a[key]).getTime(),
                );
                console.log(
                    '(new Date(value)).getTime()',
                    new Date(value).getTime(),
                );
                if (
                    a &&
                    typeof a == 'object' &&
                    new Date(a[key]).getTime() == new Date(value).getTime()
                )
                    return arrayIndex;
            } else {
                if (a && typeof a == 'object' && a[key] == value)
                    return arrayIndex;
            }
        }
    }
}

export function JSONTOCSVFormat(
    JSONData,
    ReportTitle,
    ShowLabel,
    twoLineHeader,
) {
    let arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    let CSV = '';
    if (ReportTitle) CSV += ReportTitle + '\r\n\n';
    // else CSV += '\r\n\n';
    if (ShowLabel) {
        let row = '';
        let row1 = '';
        let isTwoHeader = false;
        if (twoLineHeader) {
            for (let index in arrData[0]) {
                if (typeof arrData[0][index] === 'object') {
                    isTwoHeader = true;
                    for (let index1 in arrData[0][index]) {
                        row += index + ',';
                        row1 += index1 + ',';
                    }
                } else {
                    row += index + ',';
                    row1 += index + ',';
                }
            }
            row = row.slice(0, -1);
            CSV += row + '\r\n';
            if (isTwoHeader) {
                row1 = row1.slice(0, -1);
                CSV += row1 + '\r\n';
            }
        } else {
            for (let index in arrData[0]) {
                row += index + ',';
            }
            row = row.slice(0, -1);
            CSV += row + '\r\n';
        }
    }
    if (twoLineHeader) {
        for (let i = 0; i < arrData.length; i++) {
            let row = '';
            for (let index in arrData[i]) {
                if (typeof arrData[0][index] === 'object') {
                    for (let index1 in arrData[i][index]) {
                        row += '"' + arrData[i][index][index1] + '",';
                    }
                } else {
                    row += '"' + arrData[i][index] + '",';
                }
            }
            row.slice(0, row.length - 1);
            CSV += row + '\r\n';
        }
    } else {
        for (let i = 0; i < arrData.length; i++) {
            let row = '';
            for (let index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            CSV += row + '\r\n';
        }
    }

    if (CSV === '') {
        alert('Invalid data');
        return;
    }
    return CSV;
}

export function JSONToCSVConvertor(
    fileName,
    JSONData,
    ReportTitle,
    ShowLabel,
    twoLineHeader,
) {
    let CSV = JSONTOCSVFormat(JSONData, ReportTitle, ShowLabel, twoLineHeader);
    // fileName += ReportTitle.replace(/ /g, '_');
    let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    let link = document.createElement('a');
    link.href = uri;
    link.style = 'visibility:hidden';
    link.download = fileName + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function downloadJsonData(data, fileName) {
    const dataStr =
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(data));
    this.downloadData(dataStr, fileName);
}

export function downloadData(dataStr, fileName) {
    const dlAnchorElem = document.createElement('a');
    dlAnchorElem.setAttribute('href', dataStr);
    dlAnchorElem.setAttribute('download', fileName);
    dlAnchorElem.click();
}

export function newMoment(dateLike, format) {
    return moment(dateLike, format);
}

export function newDateMoments(dateLike, format) {
    return newMoment(dateLike, format).toDate();
}

export function vehicleTemp(obj) {
    if (
        !isEmpty(obj) &&
        !isEmpty(obj.temperature) &&
        obj.temperature != '3000' &&
        obj.temperature != '85' &&
        obj.temperature != '200'
    ) {
        return obj.temperature + ' Celsius';
    } else {
        return 'N/A';
    }
}

export function getTimeDifference(date1, date2, timeFormat) {
    var timeDifference = Math.abs(
        newDateMoments(date1) - newDateMoments(date2),
    );
    if (timeFormat == 'seconds' || timeFormat == 's') {
        return timeDifference / 1000;
    } else if (timeFormat == 'minutes' || timeFormat == 'm') {
        return timeDifference / (1000 * 60);
    } else if (timeFormat == 'hours' || timeFormat == 'h') {
        return timeDifference / (1000 * 60 * 60);
    } else {
        return timeDifference;
    }
}
export function getTimeAsDuration(time, format, returnFormat) {
    var formatMapper = {
        small: {
            days: 'd',
            hours: 'h',
            mins: 'm',
        },
        long: {
            days: 'days',
            hours: 'hours',
            mins: 'mins',
        },
    };

    time = Math.abs(time);
    format = format || 'long';
    /**
     * Need isEmpty since 0 could be ignored when just using !
     **/
    if (isEmpty(time)) {
        return '';
    }
    if (time <= 0) {
        return '<1' + formatMapper[format]['mins'];
    }

    var days = Math.floor(time / (60 * 60 * 24 * 1000));
    var hours = Math.floor((time % (60 * 60 * 24 * 1000)) / (60 * 60 * 1000));
    var mins = Math.floor((time % (60 * 60 * 1000)) / (60 * 1000));

    var retStr = '';
    if (!isEmpty(returnFormat) && returnFormat == 'dh') {
        if ((isEmpty(days) || days <= 0) && (isEmpty(hours) || hours <= 0)) {
            retStr = mins ? mins + formatMapper[format]['mins'] + ' ' : '';
        } else {
            retStr =
                (days ? days + formatMapper[format]['days'] + ' ' : '') +
                (hours ? hours + formatMapper[format]['hours'] + ' ' : '');
        }
    } else {
        retStr =
            (days ? days + formatMapper[format]['days'] + ' ' : '') +
            (hours ? hours + formatMapper[format]['hours'] + ' ' : '') +
            (mins ? mins + formatMapper[format]['mins'] + ' ' : '');
    }
    if (isEmpty(retStr)) {
        return '<1' + formatMapper[format]['mins'];
    } else {
        return retStr;
    }
}

export const showMessage = (msg, type) => {
    store.dispatch(
        enqueueSnackbar({
            message: `${msg}`,
            options: {
                key: new Date().getTime() + Math.random(),
                variant: type,
            },
        }),
    );
};
export const filterArrayEmptyValue = (arrayWithEmptyValues) => {
    return !!arrayWithEmptyValues?.length
        ? arrayWithEmptyValues.filter(function (value) {
              // Keep only non-empty values
              return (
                  value !== '' &&
                  value !== ' ' &&
                  value !== null &&
                  value !== undefined
              );
          })
        : [];
};

export const canAllow = (screen, action) => {
    const configs = store.getState().user.config;
    const permission = configs.permission ?? {};
    const pScreen =
        ScreentPermissionSet[screen?.replace(' ', '_')?.toUpperCase()];

    return (
        permission.hasOwnProperty(pScreen) &&
        permission[pScreen]?.includes(action)
    );
};

export const getActiveInitRoute = () => {
    let routes = Object.values(ScreentPermissionSet);
    let activeRoute = null;
    for (let item of routes) {
        const permission = getPermissions(item);
        if (permission.read) {
            activeRoute = item.replace('_', '-');
            break;
        }
    }
    return activeRoute ?? '404';
};

export const getPermissions = (screen) => {
    // console.log(screen, {
    //     read: canAllow(screen, ScreenActionSet.READ),
    //     update: canAllow(screen, ScreenActionSet.UPDATE),
    //     create: canAllow(screen, ScreenActionSet.CREATE),
    //     delete: canAllow(screen, ScreenActionSet.DELETE),
    // });
    // if (screen == 'Control center')
    //     return {
    //         read: true,
    //         update: true,
    //         create: true,
    //         delete: true,
    //         auth: true,
    //     };
    return {
        read: canAllow(screen, ScreenActionSet.READ),
        update: canAllow(screen, ScreenActionSet.UPDATE),
        create: canAllow(screen, ScreenActionSet.CREATE),
        delete: canAllow(screen, ScreenActionSet.DELETE),
        auth: canAllow(screen, ScreenActionSet.AUTH),
    };
};

export const getZappayAuthToken = () => {
    return store.getState().user?.config?.zappayAuthToken ?? null;
};

export const replaceTextWithCapsAndUnderscore = (text) => {
    return text?.toUpperCase()?.replace(/ /g, '_') ?? null;
};
export function isFunction(functionToCheck) {
    return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === '[object Function]'
    );
}
export function sortByMultipleProperties(
    array = [],
    sortProperties,
    order = 'asc',
    caseSensitive = false,
) {
    if (!array) return [];

    return array?.sort((a, b) => {
        for (const prop of sortProperties) {
            // const comparison = caseSensitive
            //     ? a?.[prop]?.localeCompare(b?.[prop])
            //     : a?.[prop]
            //           ?.toLowerCase()
            //           ?.localeCompare(b?.[prop]?.toLowerCase());
            const comparison = 0;
            if (a[prop] === '' && b[prop] !== '') return 1;
            if (a[prop] !== '' && b[prop] === '') return -1;
            if (a[prop] < b[prop]) return -1;
            if (a[prop] > b[prop]) return 1;
            //a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0;
            // if (comparison !== 0) {
            //     return order === 'asc' ? comparison : -comparison;
            // }
        }
        return 0;
    });
}

export function callPhoneNumber(number) {
    let phoneNumber = number?.replace('+91', '').trim();
    if (!!phoneNumber && phoneNumber?.length == 10) {
        //alert(phoneNumber);
        // Create an anchor element
        const anchor = document.createElement('a');
        // Set the href attribute to initiate a phone call
        anchor.href = `tel:+91${phoneNumber}`;
        // Append the anchor to the body
        document.body.appendChild(anchor);
        // Programmatically click the anchor tag to initiate the call
        anchor.click();
        // Remove the anchor from the DOM
        document.body.removeChild(anchor);
    }
}
export function groupBy(array, key) {
    return array.reduce((result, currentValue) => {
        // Get the value of the key to group by
        const groupKey = currentValue[key];

        // Check if the key already exists in the result object
        if (!result[groupKey]) {
            // If not, create an array for that key
            result[groupKey] = [];
        }

        // Push the current object into the respective key's array
        result[groupKey].push(currentValue);

        return result;
    }, {});
}

// Function to mask the mobile number
export const getMaskedNumber = (number) => {
    return number?.replace(/(\d{0})\d{6}(\d{4})/, '$1****$2') ?? '';
};

export const weekDays = [
    { index: 0, name: 'Sunday', shortName: 'Sun', color: '#FF5733' },
    { index: 1, name: 'Monday', shortName: 'Mon', color: '#FFC300' },
    { index: 2, name: 'Tuesday', shortName: 'Tue', color: '#36A2EB' },
    { index: 3, name: 'Wednesday', shortName: 'Wed', color: '#8E44AD' },
    { index: 4, name: 'Thursday', shortName: 'Thu', color: '#2ECC71' },
    { index: 5, name: 'Friday', shortName: 'Fri', color: '#E74C3C' },
    { index: 6, name: 'Saturday', shortName: 'Sat', color: '#3498DB' },
];

export const getWeekDaysFromRoster = (rosterDays) => {
    if (!rosterDays) return []; // Handle empty or null case

    const dayIndexes = rosterDays.split(',').map(Number); // Convert to number array
    return dayIndexes.map((index) =>
        weekDays.find((day) => day.index === index),
    );
};
